
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "contacts-import",
  components: {},
  setup() {
    const store = useStore();
    const isLoading = ref(0);
    const file = ref<File | null>();
    const uploadHistory = ref<any[]>([]);

    const sampleUrl = computed(() => {
      let acctId = store.state.AuthModule.account.id;
      if (acctId !== undefined) {
        return (
          ApiService.vueInstance.axios.defaults.baseURL +
          "samples/" +
          acctId +
          "/import_contacts"
        );
      }
      return "";
    });

    async function refreshUploadHistory() {
      isLoading.value++;
      try {
        let response = await ApiService.get("/api/v1/import_results");
        uploadHistory.value = response.data;
      } catch (e) {
        console.log(e);
      } finally {
        isLoading.value--;
      }
    }

    function onFileChanged($event: Event) {
      const target = $event.target as HTMLInputElement;
      if (target && target.files) {
        file.value = target.files[0];
      }
    }

    async function uploadFile() {
      if (file.value) {
        const formData = new FormData();
        formData.append("form[uploadedFile]", file.value);
        isLoading.value++;
        ApiService.upload("api/v1/import/contacts", formData)
          .then((response) => {
            Swal.fire({
              text: "Image saved!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .finally(() => {
            isLoading.value--;
          });
      }
    }

    const parseJson = function (json) {
      try {
        return JSON.stringify(JSON.parse(json), null, 2);
      } catch {
        return "";
      }
    };

    watch(
      () => store.state.AuthModule.account.id,
      () => {
        return;
      }
    );

    onMounted(() => {
      setCurrentPageTitle("Import Contacts");
      refreshUploadHistory();
    });
    return {
      store,
      isLoading: isLoading,
      onFileChanged,
      sampleUrl,
      uploadFile,
      uploadHistory,
      parseJson,
    };
  },
});
